import { Clipboard } from '@phosphor-icons/react';
import { IS_BROWSER, parseErrorAsString } from '@shiftsmartinc/remix-utils';
import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { useTypedRouteLoaderData } from 'remix-typedjson';
import { GlobalCopyToClipboard } from '~/global/components/GlobalCopyToClipboard';
import { RootLoaderData } from '~/root';

export interface GlobalErrorBoundaryProps {
  children?: React.ReactNode;
  className?: string;
}

/**
 * @name GlobalErrorBoundary
 * @external https://remix.run/docs/en/main/pages/v2#catchboundary-and-errorboundary
 * @description A reusable error boundary component that can be used
 * on any route. It displays a generic error message and a button to
 * reload the page. If the user is an admin or engineer, it will also
 * display the error message and a button to copy the error to the
 * clipboard.
 */
export const GlobalErrorBoundary = (props: GlobalErrorBoundaryProps) => {
  const { children, className } = props;

  // Hooks
  const error = useRouteError();
  const rootLoaderData = useTypedRouteLoaderData<RootLoaderData>('root');

  // Setup
  const user = rootLoaderData?.user;
  const isAdmin = user?.roles.includes('admin');
  const isEngineer = user?.roles.includes('engineer');
  const isDevError = isAdmin || isEngineer;
  // const isDevError = false;

  const isError = error instanceof Error;
  const isRouteError = isRouteErrorResponse(error);
  const errorMessage = isError ? error.message : 'Unknown error';
  const errorString = parseErrorAsString(error);

  // Handlers
  const onClickReload = () => {
    if (!IS_BROWSER) return;

    location.reload();
  };

  // Markup
  const reloadButton = (
    <button className="text-brand" onClick={onClickReload}>
      Please try again.
    </button>
  );

  const renderDevError = () => {
    return (
      <>
        <h1 className="text-subtitle">Error message:</h1>
        <p className="text-body text-gray-3">{errorMessage}</p>

        <code className="markdown relative">
          <GlobalCopyToClipboard
            className="absolute right-3 top-3 text-white"
            icon={false}
            message="Error copied to clipboard."
            text={errorString}
          >
            <Clipboard weight="fill" />
          </GlobalCopyToClipboard>
          <pre className="overflow-auto !bg-red text-white">
            {isError && error.stack && error.stack}
          </pre>
        </code>
      </>
    );
  };

  const renderRouteError = () => {
    if (!isRouteError) return null;
    if (children) return children;

    // When true, this is what used to go to `CatchBoundary`
    return (
      <>
        <h1 className="text-subtitle mb-2">Error Response:</h1>
        <p className="text-body">
          Status: {error.status}
          <br />
          Message: {error.data.message} {reloadButton}
        </p>
      </>
    );
  };

  const renderGeneralError = () => {
    if (isRouteError) return null;
    if (isDevError) return renderDevError();
    if (children) return children;

    return (
      <>
        <h1 className="text-subtitle mb-2 text-error">{errorMessage}</h1>
        <p className="text-body">
          Sorry, we&apos;ve encountered an error. {reloadButton}
        </p>
      </>
    );
  };

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className={className}>
      {isRouteError ? renderRouteError() : renderGeneralError()}
    </div>
  );
};
